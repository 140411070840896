// @font-face {
//     font-family:"icons";
//     src: url("/lib/fonts/icons.eot");
//     src: url("/lib/fonts/icons.eot?#iefix") format("embedded-opentype"),
//          url("/lib/fonts/icons.woff") format("woff"),
//          url("/lib/fonts/icons.ttf") format("truetype"),
//          url("/lib/fonts/icons.svg#fontcustom") format("svg");
//     font-weight: normal;
//     font-style: normal;
// }
//
// .glyph {
//   border-bottom: 1px dotted #ccc;
//   padding: 10px 0 20px;
//   margin-bottom: 20px;
// }
//
// .preview-glyphs { vertical-align: bottom; }
//
// .preview-scale {
//   color: #888;
//   font-size: 12px;
//   margin-top: 5px;
// }
//
// .step {
//   display: inline-block;
//   line-height: 1;
//   width: 10%;
// }
//
// .size-12 { font-size: 12px; }
// .size-14 { font-size: 14px; }
// .size-16 { font-size: 16px; }
// .size-18 { font-size: 18px; }
// .size-21 { font-size: 21px; }
// .size-24 { font-size: 24px; }
// .size-36 { font-size: 36px; }
// .size-48 { font-size: 48px; }
// .size-60 { font-size: 60px; }
// .size-72 { font-size: 72px; }
//
// .icon-address-book:before,
// .icon-alert:before,
// .icon-align-center:before,
// .icon-align-justify:before,
// .icon-align-left:before,
// .icon-align-right:before,
// .icon-anchor:before,
// .icon-annotate:before,
// .icon-archive:before,
// .icon-arrow-down:before,
// .icon-arrow-left:before,
// .icon-arrow-right:before,
// .icon-arrow-up:before,
// .icon-arrows-compress:before,
// .icon-arrows-expand:before,
// .icon-arrows-in:before,
// .icon-arrows-out:before,
// .icon-asl:before,
// .icon-asterisk:before,
// .icon-at-sign:before,
// .icon-background-color:before,
// .icon-battery-empty:before,
// .icon-battery-full:before,
// .icon-battery-half:before,
// .icon-bitcoin-circle:before,
// .icon-bitcoin:before,
// .icon-blind:before,
// .icon-bluetooth:before,
// .icon-bold:before,
// .icon-book-bookmark:before,
// .icon-book:before,
// .icon-bookmark:before,
// .icon-braille:before,
// .icon-burst-new:before,
// .icon-burst-sale:before,
// .icon-burst:before,
// .icon-calendar:before,
// .icon-camera:before,
// .icon-check:before,
// .icon-checkbox:before,
// .icon-clipboard-notes:before,
// .icon-clipboard-pencil:before,
// .icon-clipboard:before,
// .icon-clock:before,
// .icon-closed-caption:before,
// .icon-cloud:before,
// .icon-comment-minus:before,
// .icon-comment-quotes:before,
// .icon-comment-video:before,
// .icon-comment:before,
// .icon-comments:before,
// .icon-compass:before,
// .icon-contrast:before,
// .icon-credit-card:before,
// .icon-crop:before,
// .icon-crown:before,
// .icon-css3:before,
// .icon-database:before,
// .icon-die-five:before,
// .icon-die-four:before,
// .icon-die-one:before,
// .icon-die-six:before,
// .icon-die-three:before,
// .icon-die-two:before,
// .icon-dislike:before,
// .icon-dollar-bill:before,
// .icon-dollar:before,
// .icon-download:before,
// .icon-eject:before,
// .icon-elevator:before,
// .icon-euro:before,
// .icon-eye:before,
// .icon-fast-forward:before,
// .icon-female-symbol:before,
// .icon-female:before,
// .icon-filter:before,
// .icon-first-aid:before,
// .icon-flag:before,
// .icon-folder-add:before,
// .icon-folder-lock:before,
// .icon-folder:before,
// .icon-foot:before,
// .icon-foundation:before,
// .icon-graph-bar:before,
// .icon-graph-horizontal:before,
// .icon-graph-pie:before,
// .icon-graph-trend:before,
// .icon-guide-dog:before,
// .icon-hearing-aid:before,
// .icon-heart:before,
// .icon-home:before,
// .icon-html5:before,
// .icon-indent-less:before,
// .icon-indent-more:before,
// .icon-info:before,
// .icon-italic:before,
// .icon-key:before,
// .icon-laptop:before,
// .icon-layout:before,
// .icon-lightbulb:before,
// .icon-like:before,
// .icon-link:before,
// .icon-list-bullet:before,
// .icon-list-number:before,
// .icon-list-thumbnails:before,
// .icon-list:before,
// .icon-lock:before,
// .icon-loop:before,
// .icon-magnifying-glass:before,
// .icon-mail:before,
// .icon-male-female:before,
// .icon-male-symbol:before,
// .icon-male:before,
// .icon-map:before,
// .icon-marker:before,
// .icon-megaphone:before,
// .icon-microphone:before,
// .icon-minus-circle:before,
// .icon-minus:before,
// .icon-mobile-signal:before,
// .icon-mobile:before,
// .icon-monitor:before,
// .icon-mountains:before,
// .icon-music:before,
// .icon-next:before,
// .icon-no-dogs:before,
// .icon-no-smoking:before,
// .icon-page-add:before,
// .icon-page-copy:before,
// .icon-page-csv:before,
// .icon-page-delete:before,
// .icon-page-doc:before,
// .icon-page-edit:before,
// .icon-page-export-csv:before,
// .icon-page-export-doc:before,
// .icon-page-export-pdf:before,
// .icon-page-export:before,
// .icon-page-filled:before,
// .icon-page-multiple:before,
// .icon-page-pdf:before,
// .icon-page-remove:before,
// .icon-page-search:before,
// .icon-page:before,
// .icon-paint-bucket:before,
// .icon-paperclip:before,
// .icon-pause:before,
// .icon-paw:before,
// .icon-paypal:before,
// .icon-pencil:before,
// .icon-photo:before,
// .icon-play-circle:before,
// .icon-play-video:before,
// .icon-play:before,
// .icon-plus:before,
// .icon-pound:before,
// .icon-power:before,
// .icon-previous:before,
// .icon-price-tag:before,
// .icon-pricetag-multiple:before,
// .icon-print:before,
// .icon-prohibited:before,
// .icon-projection-screen:before,
// .icon-puzzle:before,
// .icon-quote:before,
// .icon-record:before,
// .icon-refresh:before,
// .icon-results-demographics:before,
// .icon-results:before,
// .icon-rewind-ten:before,
// .icon-rewind:before,
// .icon-rss:before,
// .icon-safety-cone:before,
// .icon-save:before,
// .icon-share:before,
// .icon-sheriff-badge:before,
// .icon-shield:before,
// .icon-shopping-bag:before,
// .icon-shopping-cart:before,
// .icon-shuffle:before,
// .icon-skull:before,
// .icon-social-500px:before,
// .icon-social-adobe:before,
// .icon-social-amazon:before,
// .icon-social-android:before,
// .icon-social-apple:before,
// .icon-social-behance:before,
// .icon-social-bing:before,
// .icon-social-blogger:before,
// .icon-social-delicious:before,
// .icon-social-designer-news:before,
// .icon-social-deviant-art:before,
// .icon-social-digg:before,
// .icon-social-dribbble:before,
// .icon-social-drive:before,
// .icon-social-dropbox:before,
// .icon-social-evernote:before,
// .icon-social-facebook:before,
// .icon-social-flickr:before,
// .icon-social-forrst:before,
// .icon-social-foursquare:before,
// .icon-social-game-center:before,
// .icon-social-github:before,
// .icon-social-google-plus:before,
// .icon-social-hacker-news:before,
// .icon-social-hi5:before,
// .icon-social-instagram:before,
// .icon-social-joomla:before,
// .icon-social-lastfm:before,
// .icon-social-linkedin:before,
// .icon-social-medium:before,
// .icon-social-myspace:before,
// .icon-social-orkut:before,
// .icon-social-path:before,
// .icon-social-picasa:before,
// .icon-social-pinterest:before,
// .icon-social-rdio:before,
// .icon-social-reddit:before,
// .icon-social-skillshare:before,
// .icon-social-skype:before,
// .icon-social-smashing-mag:before,
// .icon-social-snapchat:before,
// .icon-social-spotify:before,
// .icon-social-squidoo:before,
// .icon-social-stack-overflow:before,
// .icon-social-steam:before,
// .icon-social-stumbleupon:before,
// .icon-social-treehouse:before,
// .icon-social-tumblr:before,
// .icon-social-twitter:before,
// .icon-social-vimeo:before,
// .icon-social-windows:before,
// .icon-social-xbox:before,
// .icon-social-yahoo:before,
// .icon-social-yelp:before,
// .icon-social-youtube:before,
// .icon-social-zerply:before,
// .icon-social-zurb:before,
// .icon-sound:before,
// .icon-star:before,
// .icon-stop:before,
// .icon-strikethrough:before,
// .icon-subscript:before,
// .icon-superscript:before,
// .icon-tablet-landscape:before,
// .icon-tablet-portrait:before,
// .icon-target-two:before,
// .icon-target:before,
// .icon-telephone-accessible:before,
// .icon-telephone:before,
// .icon-text-color:before,
// .icon-thumbnails:before,
// .icon-ticket:before,
// .icon-torso-business:before,
// .icon-torso-female:before,
// .icon-torso:before,
// .icon-torsos-all-female:before,
// .icon-torsos-all:before,
// .icon-torsos-female-male:before,
// .icon-torsos-male-female:before,
// .icon-torsos:before,
// .icon-trash:before,
// .icon-trees:before,
// .icon-trophy:before,
// .icon-underline:before,
// .icon-universal-access:before,
// .icon-unlink:before,
// .icon-unlock:before,
// .icon-upload-cloud:before,
// .icon-upload:before,
// .icon-usb:before,
// .icon-video:before,
// .icon-volume-none:before,
// .icon-volume-strike:before,
// .icon-volume:before,
// .icon-web:before,
// .icon-wheelchair:before,
// .icon-widget:before,
// .icon-wrench:before,
// .icon-x-circle:before,
// .icon-x:before,
// .icon-yen:before,
// .icon-zoom-in:before,
// .icon-zoom-out:before {
//   font-family: "foundation-icons";
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
//   display: inline-block;
//   text-decoration: inherit;
// }
//
// .icon-address-book:before { content: "\f100"; }
// .icon-alert:before { content: "\f101"; }
// .icon-align-center:before { content: "\f102"; }
// .icon-align-justify:before { content: "\f103"; }
// .icon-align-left:before { content: "\f104"; }
// .icon-align-right:before { content: "\f105"; }
// .icon-anchor:before { content: "\f106"; }
// .icon-annotate:before { content: "\f107"; }
// .icon-archive:before { content: "\f108"; }
// .icon-arrow-down:before { content: "\f109"; }
// .icon-arrow-left:before { content: "\f10a"; }
// .icon-arrow-right:before { content: "\f10b"; }
// .icon-arrow-up:before { content: "\f10c"; }
// .icon-arrows-compress:before { content: "\f10d"; }
// .icon-arrows-expand:before { content: "\f10e"; }
// .icon-arrows-in:before { content: "\f10f"; }
// .icon-arrows-out:before { content: "\f110"; }
// .icon-asl:before { content: "\f111"; }
// .icon-asterisk:before { content: "\f112"; }
// .icon-at-sign:before { content: "\f113"; }
// .icon-background-color:before { content: "\f114"; }
// .icon-battery-empty:before { content: "\f115"; }
// .icon-battery-full:before { content: "\f116"; }
// .icon-battery-half:before { content: "\f117"; }
// .icon-bitcoin-circle:before { content: "\f118"; }
// .icon-bitcoin:before { content: "\f119"; }
// .icon-blind:before { content: "\f11a"; }
// .icon-bluetooth:before { content: "\f11b"; }
// .icon-bold:before { content: "\f11c"; }
// .icon-book-bookmark:before { content: "\f11d"; }
// .icon-book:before { content: "\f11e"; }
// .icon-bookmark:before { content: "\f11f"; }
// .icon-braille:before { content: "\f120"; }
// .icon-burst-new:before { content: "\f121"; }
// .icon-burst-sale:before { content: "\f122"; }
// .icon-burst:before { content: "\f123"; }
// .icon-calendar:before { content: "\f124"; }
// .icon-camera:before { content: "\f125"; }
// .icon-check:before { content: "\f126"; }
// .icon-checkbox:before { content: "\f127"; }
// .icon-clipboard-notes:before { content: "\f128"; }
// .icon-clipboard-pencil:before { content: "\f129"; }
// .icon-clipboard:before { content: "\f12a"; }
// .icon-clock:before { content: "\f12b"; }
// .icon-closed-caption:before { content: "\f12c"; }
// .icon-cloud:before { content: "\f12d"; }
// .icon-comment-minus:before { content: "\f12e"; }
// .icon-comment-quotes:before { content: "\f12f"; }
// .icon-comment-video:before { content: "\f130"; }
// .icon-comment:before { content: "\f131"; }
// .icon-comments:before { content: "\f132"; }
// .icon-compass:before { content: "\f133"; }
// .icon-contrast:before { content: "\f134"; }
// .icon-credit-card:before { content: "\f135"; }
// .icon-crop:before { content: "\f136"; }
// .icon-crown:before { content: "\f137"; }
// .icon-css3:before { content: "\f138"; }
// .icon-database:before { content: "\f139"; }
// .icon-die-five:before { content: "\f13a"; }
// .icon-die-four:before { content: "\f13b"; }
// .icon-die-one:before { content: "\f13c"; }
// .icon-die-six:before { content: "\f13d"; }
// .icon-die-three:before { content: "\f13e"; }
// .icon-die-two:before { content: "\f13f"; }
// .icon-dislike:before { content: "\f140"; }
// .icon-dollar-bill:before { content: "\f141"; }
// .icon-dollar:before { content: "\f142"; }
// .icon-download:before { content: "\f143"; }
// .icon-eject:before { content: "\f144"; }
// .icon-elevator:before { content: "\f145"; }
// .icon-euro:before { content: "\f146"; }
// .icon-eye:before { content: "\f147"; }
// .icon-fast-forward:before { content: "\f148"; }
// .icon-female-symbol:before { content: "\f149"; }
// .icon-female:before { content: "\f14a"; }
// .icon-filter:before { content: "\f14b"; }
// .icon-first-aid:before { content: "\f14c"; }
// .icon-flag:before { content: "\f14d"; }
// .icon-folder-add:before { content: "\f14e"; }
// .icon-folder-lock:before { content: "\f14f"; }
// .icon-folder:before { content: "\f150"; }
// .icon-foot:before { content: "\f151"; }
// .icon-foundation:before { content: "\f152"; }
// .icon-graph-bar:before { content: "\f153"; }
// .icon-graph-horizontal:before { content: "\f154"; }
// .icon-graph-pie:before { content: "\f155"; }
// .icon-graph-trend:before { content: "\f156"; }
// .icon-guide-dog:before { content: "\f157"; }
// .icon-hearing-aid:before { content: "\f158"; }
// .icon-heart:before { content: "\f159"; }
// .icon-home:before { content: "\f15a"; }
// .icon-html5:before { content: "\f15b"; }
// .icon-indent-less:before { content: "\f15c"; }
// .icon-indent-more:before { content: "\f15d"; }
// .icon-info:before { content: "\f15e"; }
// .icon-italic:before { content: "\f15f"; }
// .icon-key:before { content: "\f160"; }
// .icon-laptop:before { content: "\f161"; }
// .icon-layout:before { content: "\f162"; }
// .icon-lightbulb:before { content: "\f163"; }
// .icon-like:before { content: "\f164"; }
// .icon-link:before { content: "\f165"; }
// .icon-list-bullet:before { content: "\f166"; }
// .icon-list-number:before { content: "\f167"; }
// .icon-list-thumbnails:before { content: "\f168"; }
// .icon-list:before { content: "\f169"; }
// .icon-lock:before { content: "\f16a"; }
// .icon-loop:before { content: "\f16b"; }
// .icon-magnifying-glass:before { content: "\f16c"; }
// .icon-mail:before { content: "\f16d"; }
// .icon-male-female:before { content: "\f16e"; }
// .icon-male-symbol:before { content: "\f16f"; }
// .icon-male:before { content: "\f170"; }
// .icon-map:before { content: "\f171"; }
// .icon-marker:before { content: "\f172"; }
// .icon-megaphone:before { content: "\f173"; }
// .icon-microphone:before { content: "\f174"; }
// .icon-minus-circle:before { content: "\f175"; }
// .icon-minus:before { content: "\f176"; }
// .icon-mobile-signal:before { content: "\f177"; }
// .icon-mobile:before { content: "\f178"; }
// .icon-monitor:before { content: "\f179"; }
// .icon-mountains:before { content: "\f17a"; }
// .icon-music:before { content: "\f17b"; }
// .icon-next:before { content: "\f17c"; }
// .icon-no-dogs:before { content: "\f17d"; }
// .icon-no-smoking:before { content: "\f17e"; }
// .icon-page-add:before { content: "\f17f"; }
// .icon-page-copy:before { content: "\f180"; }
// .icon-page-csv:before { content: "\f181"; }
// .icon-page-delete:before { content: "\f182"; }
// .icon-page-doc:before { content: "\f183"; }
// .icon-page-edit:before { content: "\f184"; }
// .icon-page-export-csv:before { content: "\f185"; }
// .icon-page-export-doc:before { content: "\f186"; }
// .icon-page-export-pdf:before { content: "\f187"; }
// .icon-page-export:before { content: "\f188"; }
// .icon-page-filled:before { content: "\f189"; }
// .icon-page-multiple:before { content: "\f18a"; }
// .icon-page-pdf:before { content: "\f18b"; }
// .icon-page-remove:before { content: "\f18c"; }
// .icon-page-search:before { content: "\f18d"; }
// .icon-page:before { content: "\f18e"; }
// .icon-paint-bucket:before { content: "\f18f"; }
// .icon-paperclip:before { content: "\f190"; }
// .icon-pause:before { content: "\f191"; }
// .icon-paw:before { content: "\f192"; }
// .icon-paypal:before { content: "\f193"; }
// .icon-pencil:before { content: "\f194"; }
// .icon-photo:before { content: "\f195"; }
// .icon-play-circle:before { content: "\f196"; }
// .icon-play-video:before { content: "\f197"; }
// .icon-play:before { content: "\f198"; }
// .icon-plus:before { content: "\f199"; }
// .icon-pound:before { content: "\f19a"; }
// .icon-power:before { content: "\f19b"; }
// .icon-previous:before { content: "\f19c"; }
// .icon-price-tag:before { content: "\f19d"; }
// .icon-pricetag-multiple:before { content: "\f19e"; }
// .icon-print:before { content: "\f19f"; }
// .icon-prohibited:before { content: "\f1a0"; }
// .icon-projection-screen:before { content: "\f1a1"; }
// .icon-puzzle:before { content: "\f1a2"; }
// .icon-quote:before { content: "\f1a3"; }
// .icon-record:before { content: "\f1a4"; }
// .icon-refresh:before { content: "\f1a5"; }
// .icon-results-demographics:before { content: "\f1a6"; }
// .icon-results:before { content: "\f1a7"; }
// .icon-rewind-ten:before { content: "\f1a8"; }
// .icon-rewind:before { content: "\f1a9"; }
// .icon-rss:before { content: "\f1aa"; }
// .icon-safety-cone:before { content: "\f1ab"; }
// .icon-save:before { content: "\f1ac"; }
// .icon-share:before { content: "\f1ad"; }
// .icon-sheriff-badge:before { content: "\f1ae"; }
// .icon-shield:before { content: "\f1af"; }
// .icon-shopping-bag:before { content: "\f1b0"; }
// .icon-shopping-cart:before { content: "\f1b1"; }
// .icon-shuffle:before { content: "\f1b2"; }
// .icon-skull:before { content: "\f1b3"; }
// .icon-social-500px:before { content: "\f1b4"; }
// .icon-social-adobe:before { content: "\f1b5"; }
// .icon-social-amazon:before { content: "\f1b6"; }
// .icon-social-android:before { content: "\f1b7"; }
// .icon-social-apple:before { content: "\f1b8"; }
// .icon-social-behance:before { content: "\f1b9"; }
// .icon-social-bing:before { content: "\f1ba"; }
// .icon-social-blogger:before { content: "\f1bb"; }
// .icon-social-delicious:before { content: "\f1bc"; }
// .icon-social-designer-news:before { content: "\f1bd"; }
// .icon-social-deviant-art:before { content: "\f1be"; }
// .icon-social-digg:before { content: "\f1bf"; }
// .icon-social-dribbble:before { content: "\f1c0"; }
// .icon-social-drive:before { content: "\f1c1"; }
// .icon-social-dropbox:before { content: "\f1c2"; }
// .icon-social-evernote:before { content: "\f1c3"; }
// .icon-social-facebook:before { content: "\f1c4"; }
// .icon-social-flickr:before { content: "\f1c5"; }
// .icon-social-forrst:before { content: "\f1c6"; }
// .icon-social-foursquare:before { content: "\f1c7"; }
// .icon-social-game-center:before { content: "\f1c8"; }
// .icon-social-github:before { content: "\f1c9"; }
// .icon-social-google-plus:before { content: "\f1ca"; }
// .icon-social-hacker-news:before { content: "\f1cb"; }
// .icon-social-hi5:before { content: "\f1cc"; }
// .icon-social-instagram:before { content: "\f1cd"; }
// .icon-social-joomla:before { content: "\f1ce"; }
// .icon-social-lastfm:before { content: "\f1cf"; }
// .icon-social-linkedin:before { content: "\f1d0"; }
// .icon-social-medium:before { content: "\f1d1"; }
// .icon-social-myspace:before { content: "\f1d2"; }
// .icon-social-orkut:before { content: "\f1d3"; }
// .icon-social-path:before { content: "\f1d4"; }
// .icon-social-picasa:before { content: "\f1d5"; }
// .icon-social-pinterest:before { content: "\f1d6"; }
// .icon-social-rdio:before { content: "\f1d7"; }
// .icon-social-reddit:before { content: "\f1d8"; }
// .icon-social-skillshare:before { content: "\f1d9"; }
// .icon-social-skype:before { content: "\f1da"; }
// .icon-social-smashing-mag:before { content: "\f1db"; }
// .icon-social-snapchat:before { content: "\f1dc"; }
// .icon-social-spotify:before { content: "\f1dd"; }
// .icon-social-squidoo:before { content: "\f1de"; }
// .icon-social-stack-overflow:before { content: "\f1df"; }
// .icon-social-steam:before { content: "\f1e0"; }
// .icon-social-stumbleupon:before { content: "\f1e1"; }
// .icon-social-treehouse:before { content: "\f1e2"; }
// .icon-social-tumblr:before { content: "\f1e3"; }
// .icon-social-twitter:before { content: "\f1e4"; }
// .icon-social-vimeo:before { content: "\f1e5"; }
// .icon-social-windows:before { content: "\f1e6"; }
// .icon-social-xbox:before { content: "\f1e7"; }
// .icon-social-yahoo:before { content: "\f1e8"; }
// .icon-social-yelp:before { content: "\f1e9"; }
// .icon-social-youtube:before { content: "\f1ea"; }
// .icon-social-zerply:before { content: "\f1eb"; }
// .icon-social-zurb:before { content: "\f1ec"; }
// .icon-sound:before { content: "\f1ed"; }
// .icon-star:before { content: "\f1ee"; }
// .icon-stop:before { content: "\f1ef"; }
// .icon-strikethrough:before { content: "\f1f0"; }
// .icon-subscript:before { content: "\f1f1"; }
// .icon-superscript:before { content: "\f1f2"; }
// .icon-tablet-landscape:before { content: "\f1f3"; }
// .icon-tablet-portrait:before { content: "\f1f4"; }
// .icon-target-two:before { content: "\f1f5"; }
// .icon-target:before { content: "\f1f6"; }
// .icon-telephone-accessible:before { content: "\f1f7"; }
// .icon-telephone:before { content: "\f1f8"; }
// .icon-text-color:before { content: "\f1f9"; }
// .icon-thumbnails:before { content: "\f1fa"; }
// .icon-ticket:before { content: "\f1fb"; }
// .icon-torso-business:before { content: "\f1fc"; }
// .icon-torso-female:before { content: "\f1fd"; }
// .icon-torso:before { content: "\f1fe"; }
// .icon-torsos-all-female:before { content: "\f1ff"; }
// .icon-torsos-all:before { content: "\f200"; }
// .icon-torsos-female-male:before { content: "\f201"; }
// .icon-torsos-male-female:before { content: "\f202"; }
// .icon-torsos:before { content: "\f203"; }
// .icon-trash:before { content: "\f204"; }
// .icon-trees:before { content: "\f205"; }
// .icon-trophy:before { content: "\f206"; }
// .icon-underline:before { content: "\f207"; }
// .icon-universal-access:before { content: "\f208"; }
// .icon-unlink:before { content: "\f209"; }
// .icon-unlock:before { content: "\f20a"; }
// .icon-upload-cloud:before { content: "\f20b"; }
// .icon-upload:before { content: "\f20c"; }
// .icon-usb:before { content: "\f20d"; }
// .icon-video:before { content: "\f20e"; }
// .icon-volume-none:before { content: "\f20f"; }
// .icon-volume-strike:before { content: "\f210"; }
// .icon-volume:before { content: "\f211"; }
// .icon-web:before { content: "\f212"; }
// .icon-wheelchair:before { content: "\f213"; }
// .icon-widget:before { content: "\f214"; }
// .icon-wrench:before { content: "\f215"; }
// .icon-x-circle:before { content: "\f216"; }
// .icon-x:before { content: "\f217"; }
// .icon-yen:before { content: "\f218"; }
// .icon-zoom-in:before { content: "\f219"; }
// .icon-zoom-out:before { content: "\f21a"; }



////////////////////////////////////////////////////////////////
@font-face {
    font-family:"icons";
    src: url("/lib/fonts/icons.eot");
    src: url("/lib/fonts/icons.eot?#iefix") format("embedded-opentype"),
         url("/lib/fonts/icons.woff") format("woff"),
         url("/lib/fonts/icons.ttf") format("truetype"),
         url("/lib/fonts/icons.svg#fontcustom") format("svg");
    font-weight: normal;
    font-style: normal;
}

.glyph {
  border-bottom: 1px dotted #ccc;
  padding: 10px 0 0px;
  margin-bottom: 0px;
}
.preview-glyphs { vertical-align: bottom; }
.preview-scale {
  color: #888;
  font-size: 12px;
  margin-top: 5px;
}
.step {
  display: inline-block;
  line-height: 1;
  padding:0 .25rem;
  width: auto;
}
@media screen and (min-width: 40em) {
  .step {
  display: inline-block;
  line-height: 1;
  padding:0 .125rem;
  width: auto;
  }

}
.size-12 { font-size: 12px; }
.size-14 { font-size: 14px; }
.size-16 { font-size: 16px; }
.size-18 { font-size: 18px; }
.size-21 { font-size: 21px; }
.size-24 { font-size: 24px; }
.size-36 { font-size: 36px; }
.size-48 { font-size: 48px; }
.size-60 { font-size: 60px; }
.size-72 { font-size: 72px; }
.usage { margin:10px auto 0; }
.usage input {
  font-family: monospace;
  margin-right: 3px;
  padding: 2px 5px;
  text-align: center;
}
.usage .point { width: 150px; }
.usage .class { width: 250px; }
.icon-address-book:before,
.icon-alert:before,
.icon-align-center:before,
.icon-align-justify:before,
.icon-align-left:before,
.icon-align-right:before,
.icon-anchor:before,
.icon-annotate:before,
.icon-archive:before,
.icon-arrow-down:before,
.icon-arrow-left:before,
.icon-arrow-right:before,
.icon-arrow-up:before,
.icon-arrows-compress:before,
.icon-arrows-expand:before,
.icon-arrows-in:before,
.icon-arrows-out:before,
.icon-asl:before,
.icon-asterisk:before,
.icon-at-sign:before,
.icon-background-color:before,
.icon-battery-empty:before,
.icon-battery-full:before,
.icon-battery-half:before,
.icon-bitcoin-circle:before,
.icon-bitcoin:before,
.icon-blind:before,
.icon-bluetooth:before,
.icon-bold:before,
.icon-book-bookmark:before,
.icon-book:before,
.icon-bookmark:before,
.icon-braille:before,
.icon-burst-new:before,
.icon-burst-sale:before,
.icon-burst:before,
.icon-calendar:before,
.icon-camera:before,
.icon-check:before,
.icon-checkbox:before,
.icon-clipboard-notes:before,
.icon-clipboard-pencil:before,
.icon-clipboard:before,
.icon-clock:before,
.icon-closed-caption:before,
.icon-cloud:before,
.icon-comment-minus:before,
.icon-comment-quotes:before,
.icon-comment-video:before,
.icon-comment:before,
.icon-comments:before,
.icon-compass:before,
.icon-contrast:before,
.icon-credit-card:before,
.icon-crop:before,
.icon-crown:before,
.icon-css3:before,
.icon-database:before,
.icon-die-five:before,
.icon-die-four:before,
.icon-die-one:before,
.icon-die-six:before,
.icon-die-three:before,
.icon-die-two:before,
.icon-dislike:before,
.icon-dollar-bill:before,
.icon-dollar:before,
.icon-download:before,
.icon-eject:before,
.icon-elevator:before,
.icon-euro:before,
.icon-eye:before,
.icon-fast-forward:before,
.icon-female-symbol:before,
.icon-female:before,
.icon-filter:before,
.icon-first-aid:before,
.icon-flag:before,
.icon-folder-add:before,
.icon-folder-lock:before,
.icon-folder:before,
.icon-foot:before,
.icon-foundation:before,
.icon-graph-bar:before,
.icon-graph-horizontal:before,
.icon-graph-pie:before,
.icon-graph-trend:before,
.icon-guide-dog:before,
.icon-hearing-aid:before,
.icon-heart:before,
.icon-home:before,
.icon-html5:before,
.icon-indent-less:before,
.icon-indent-more:before,
.icon-info:before,
.icon-italic:before,
.icon-key:before,
.icon-laptop:before,
.icon-layout:before,
.icon-lightbulb:before,
.icon-like:before,
.icon-link:before,
.icon-list-bullet:before,
.icon-list-number:before,
.icon-list-thumbnails:before,
.icon-list:before,
.icon-lock:before,
.icon-loop:before,
.icon-magnifying-glass:before,
.icon-mail:before,
.icon-male-female:before,
.icon-male-symbol:before,
.icon-male:before,
.icon-map:before,
.icon-marker:before,
.icon-megaphone:before,
.icon-microphone:before,
.icon-minus-circle:before,
.icon-minus:before,
.icon-mobile-signal:before,
.icon-mobile:before,
.icon-monitor:before,
.icon-mountains:before,
.icon-music:before,
.icon-next:before,
.icon-no-dogs:before,
.icon-no-smoking:before,
.icon-page-add:before,
.icon-page-copy:before,
.icon-page-csv:before,
.icon-page-delete:before,
.icon-page-doc:before,
.icon-page-edit:before,
.icon-page-export-csv:before,
.icon-page-export-doc:before,
.icon-page-export-pdf:before,
.icon-page-export:before,
.icon-page-filled:before,
.icon-page-multiple:before,
.icon-page-pdf:before,
.icon-page-remove:before,
.icon-page-search:before,
.icon-page:before,
.icon-paint-bucket:before,
.icon-paperclip:before,
.icon-pause:before,
.icon-paw:before,
.icon-paypal:before,
.icon-pencil:before,
.icon-photo:before,
.icon-play-circle:before,
.icon-play-video:before,
.icon-play:before,
.icon-plus:before,
.icon-pound:before,
.icon-power:before,
.icon-previous:before,
.icon-price-tag:before,
.icon-pricetag-multiple:before,
.icon-print:before,
.icon-prohibited:before,
.icon-projection-screen:before,
.icon-puzzle:before,
.icon-quote:before,
.icon-record:before,
.icon-refresh:before,
.icon-results-demographics:before,
.icon-results:before,
.icon-rewind-ten:before,
.icon-rewind:before,
.icon-rss:before,
.icon-safety-cone:before,
.icon-save:before,
.icon-share:before,
.icon-sheriff-badge:before,
.icon-shield:before,
.icon-shopping-bag:before,
.icon-shopping-cart:before,
.icon-shuffle:before,
.icon-skull:before,
.icon-social-500px:before,
.icon-social-adobe:before,
.icon-social-amazon:before,
.icon-social-android:before,
.icon-social-apple:before,
.icon-social-behance:before,
.icon-social-bing:before,
.icon-social-blogger:before,
.icon-social-delicious:before,
.icon-social-designer-news:before,
.icon-social-deviant-art:before,
.icon-social-digg:before,
.icon-social-dribbble:before,
.icon-social-drive:before,
.icon-social-dropbox:before,
.icon-social-evernote:before,
.icon-social-facebook:before,
.icon-social-flickr:before,
.icon-social-forrst:before,
.icon-social-foursquare:before,
.icon-social-game-center:before,
.icon-social-github:before,
.icon-social-google-plus:before,
.icon-social-hacker-news:before,
.icon-social-hi5:before,
.icon-social-instagram:before,
.icon-social-joomla:before,
.icon-social-lastfm:before,
.icon-social-linkedin:before,
.icon-social-medium:before,
.icon-social-myspace:before,
.icon-social-orkut:before,
.icon-social-path:before,
.icon-social-picasa:before,
.icon-social-pinterest:before,
.icon-social-rdio:before,
.icon-social-reddit:before,
.icon-social-skillshare:before,
.icon-social-skype:before,
.icon-social-smashing-mag:before,
.icon-social-snapchat:before,
.icon-social-spotify:before,
.icon-social-squidoo:before,
.icon-social-stack-overflow:before,
.icon-social-steam:before,
.icon-social-stumbleupon:before,
.icon-social-treehouse:before,
.icon-social-tumblr:before,
.icon-social-twitter:before,
.icon-social-vimeo:before,
.icon-social-windows:before,
.icon-social-xbox:before,
.icon-social-yahoo:before,
.icon-social-yelp:before,
.icon-social-youtube:before,
.icon-social-zerply:before,
.icon-social-zurb:before,
.icon-sound:before,
.icon-star:before,
.icon-stop:before,
.icon-strikethrough:before,
.icon-subscript:before,
.icon-superscript:before,
.icon-tablet-landscape:before,
.icon-tablet-portrait:before,
.icon-target-two:before,
.icon-target:before,
.icon-telephone-accessible:before,
.icon-telephone:before,
.icon-text-color:before,
.icon-thumbnails:before,
.icon-ticket:before,
.icon-torso-business:before,
.icon-torso-female:before,
.icon-torso:before,
.icon-torsos-all-female:before,
.icon-torsos-all:before,
.icon-torsos-female-male:before,
.icon-torsos-male-female:before,
.icon-torsos:before,
.icon-trash:before,
.icon-trees:before,
.icon-trophy:before,
.icon-underline:before,
.icon-universal-access:before,
.icon-unlink:before,
.icon-unlock:before,
.icon-upload-cloud:before,
.icon-upload:before,
.icon-usb:before,
.icon-video:before,
.icon-volume-none:before,
.icon-volume-strike:before,
.icon-volume:before,
.icon-web:before,
.icon-wheelchair:before,
.icon-widget:before,
.icon-wrench:before,
.icon-x-circle:before,
.icon-x:before,
.icon-yen:before,
.icon-zoom-in:before,
.icon-zoom-out:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

.icon-address-book:before { content: "\f100"; }
.icon-alert:before { content: "\f101"; }
.icon-align-center:before { content: "\f102"; }
.icon-align-justify:before { content: "\f103"; }
.icon-align-left:before { content: "\f104"; }
.icon-align-right:before { content: "\f105"; }
.icon-anchor:before { content: "\f106"; }
.icon-annotate:before { content: "\f107"; }
.icon-archive:before { content: "\f108"; }
.icon-arrow-down:before { content: "\f109"; }
.icon-arrow-left:before { content: "\f10a"; }
.icon-arrow-right:before { content: "\f10b"; }
.icon-arrow-up:before { content: "\f10c"; }
.icon-arrows-compress:before { content: "\f10d"; }
.icon-arrows-expand:before { content: "\f10e"; }
.icon-arrows-in:before { content: "\f10f"; }
.icon-arrows-out:before { content: "\f110"; }
.icon-asl:before { content: "\f111"; }
.icon-asterisk:before { content: "\f112"; }
.icon-at-sign:before { content: "\f113"; }
.icon-background-color:before { content: "\f114"; }
.icon-battery-empty:before { content: "\f115"; }
.icon-battery-full:before { content: "\f116"; }
.icon-battery-half:before { content: "\f117"; }
.icon-bitcoin-circle:before { content: "\f118"; }
.icon-bitcoin:before { content: "\f119"; }
.icon-blind:before { content: "\f11a"; }
.icon-bluetooth:before { content: "\f11b"; }
.icon-bold:before { content: "\f11c"; }
.icon-book-bookmark:before { content: "\f11d"; }
.icon-book:before { content: "\f11e"; }
.icon-bookmark:before { content: "\f11f"; }
.icon-braille:before { content: "\f120"; }
.icon-burst-new:before { content: "\f121"; }
.icon-burst-sale:before { content: "\f122"; }
.icon-burst:before { content: "\f123"; }
.icon-calendar:before { content: "\f124"; }
.icon-camera:before { content: "\f125"; }
.icon-check:before { content: "\f126"; }
.icon-checkbox:before { content: "\f127"; }
.icon-clipboard-notes:before { content: "\f128"; }
.icon-clipboard-pencil:before { content: "\f129"; }
.icon-clipboard:before { content: "\f12a"; }
.icon-clock:before { content: "\f12b"; }
.icon-closed-caption:before { content: "\f12c"; }
.icon-cloud:before { content: "\f12d"; }
.icon-comment-minus:before { content: "\f12e"; }
.icon-comment-quotes:before { content: "\f12f"; }
.icon-comment-video:before { content: "\f130"; }
.icon-comment:before { content: "\f131"; }
.icon-comments:before { content: "\f132"; }
.icon-compass:before { content: "\f133"; }
.icon-contrast:before { content: "\f134"; }
.icon-credit-card:before { content: "\f135"; }
.icon-crop:before { content: "\f136"; }
.icon-crown:before { content: "\f137"; }
.icon-css3:before { content: "\f138"; }
.icon-database:before { content: "\f139"; }
.icon-die-five:before { content: "\f13a"; }
.icon-die-four:before { content: "\f13b"; }
.icon-die-one:before { content: "\f13c"; }
.icon-die-six:before { content: "\f13d"; }
.icon-die-three:before { content: "\f13e"; }
.icon-die-two:before { content: "\f13f"; }
.icon-dislike:before { content: "\f140"; }
.icon-dollar-bill:before { content: "\f141"; }
.icon-dollar:before { content: "\f142"; }
.icon-download:before { content: "\f143"; }
.icon-eject:before { content: "\f144"; }
.icon-elevator:before { content: "\f145"; }
.icon-euro:before { content: "\f146"; }
.icon-eye:before { content: "\f147"; }
.icon-fast-forward:before { content: "\f148"; }
.icon-female-symbol:before { content: "\f149"; }
.icon-female:before { content: "\f14a"; }
.icon-filter:before { content: "\f14b"; }
.icon-first-aid:before { content: "\f14c"; }
.icon-flag:before { content: "\f14d"; }
.icon-folder-add:before { content: "\f14e"; }
.icon-folder-lock:before { content: "\f14f"; }
.icon-folder:before { content: "\f150"; }
.icon-foot:before { content: "\f151"; }
.icon-foundation:before { content: "\f152"; }
.icon-graph-bar:before { content: "\f153"; }
.icon-graph-horizontal:before { content: "\f154"; }
.icon-graph-pie:before { content: "\f155"; }
.icon-graph-trend:before { content: "\f156"; }
.icon-guide-dog:before { content: "\f157"; }
.icon-hearing-aid:before { content: "\f158"; }
.icon-heart:before { content: "\f159"; }
.icon-home:before { content: "\f15a"; }
.icon-html5:before { content: "\f15b"; }
.icon-indent-less:before { content: "\f15c"; }
.icon-indent-more:before { content: "\f15d"; }
.icon-info:before { content: "\f15e"; }
.icon-italic:before { content: "\f15f"; }
.icon-key:before { content: "\f160"; }
.icon-laptop:before { content: "\f161"; }
.icon-layout:before { content: "\f162"; }
.icon-lightbulb:before { content: "\f163"; }
.icon-like:before { content: "\f164"; }
.icon-link:before { content: "\f165"; }
.icon-list-bullet:before { content: "\f166"; }
.icon-list-number:before { content: "\f167"; }
.icon-list-thumbnails:before { content: "\f168"; }
.icon-list:before { content: "\f169"; }
.icon-lock:before { content: "\f16a"; }
.icon-loop:before { content: "\f16b"; }
.icon-magnifying-glass:before { content: "\f16c"; }
.icon-mail:before { content: "\f16d"; }
.icon-male-female:before { content: "\f16e"; }
.icon-male-symbol:before { content: "\f16f"; }
.icon-male:before { content: "\f170"; }
.icon-map:before { content: "\f171"; }
.icon-marker:before { content: "\f172"; }
.icon-megaphone:before { content: "\f173"; }
.icon-microphone:before { content: "\f174"; }
.icon-minus-circle:before { content: "\f175"; }
.icon-minus:before { content: "\f176"; }
.icon-mobile-signal:before { content: "\f177"; }
.icon-mobile:before { content: "\f178"; }
.icon-monitor:before { content: "\f179"; }
.icon-mountains:before { content: "\f17a"; }
.icon-music:before { content: "\f17b"; }
.icon-next:before { content: "\f17c"; }
.icon-no-dogs:before { content: "\f17d"; }
.icon-no-smoking:before { content: "\f17e"; }
.icon-page-add:before { content: "\f17f"; }
.icon-page-copy:before { content: "\f180"; }
.icon-page-csv:before { content: "\f181"; }
.icon-page-delete:before { content: "\f182"; }
.icon-page-doc:before { content: "\f183"; }
.icon-page-edit:before { content: "\f184"; }
.icon-page-export-csv:before { content: "\f185"; }
.icon-page-export-doc:before { content: "\f186"; }
.icon-page-export-pdf:before { content: "\f187"; }
.icon-page-export:before { content: "\f188"; }
.icon-page-filled:before { content: "\f189"; }
.icon-page-multiple:before { content: "\f18a"; }
.icon-page-pdf:before { content: "\f18b"; }
.icon-page-remove:before { content: "\f18c"; }
.icon-page-search:before { content: "\f18d"; }
.icon-page:before { content: "\f18e"; }
.icon-paint-bucket:before { content: "\f18f"; }
.icon-paperclip:before { content: "\f190"; }
.icon-pause:before { content: "\f191"; }
.icon-paw:before { content: "\f192"; }
.icon-paypal:before { content: "\f193"; }
.icon-pencil:before { content: "\f194"; }
.icon-photo:before { content: "\f195"; }
.icon-play-circle:before { content: "\f196"; }
.icon-play-video:before { content: "\f197"; }
.icon-play:before { content: "\f198"; }
.icon-plus:before { content: "\f199"; }
.icon-pound:before { content: "\f19a"; }
.icon-power:before { content: "\f19b"; }
.icon-previous:before { content: "\f19c"; }
.icon-price-tag:before { content: "\f19d"; }
.icon-pricetag-multiple:before { content: "\f19e"; }
.icon-print:before { content: "\f19f"; }
.icon-prohibited:before { content: "\f1a0"; }
.icon-projection-screen:before { content: "\f1a1"; }
.icon-puzzle:before { content: "\f1a2"; }
.icon-quote:before { content: "\f1a3"; }
.icon-record:before { content: "\f1a4"; }
.icon-refresh:before { content: "\f1a5"; }
.icon-results-demographics:before { content: "\f1a6"; }
.icon-results:before { content: "\f1a7"; }
.icon-rewind-ten:before { content: "\f1a8"; }
.icon-rewind:before { content: "\f1a9"; }
.icon-rss:before { content: "\f1aa"; }
.icon-safety-cone:before { content: "\f1ab"; }
.icon-save:before { content: "\f1ac"; }
.icon-share:before { content: "\f1ad"; }
.icon-sheriff-badge:before { content: "\f1ae"; }
.icon-shield:before { content: "\f1af"; }
.icon-shopping-bag:before { content: "\f1b0"; }
.icon-shopping-cart:before { content: "\f1b1"; }
.icon-shuffle:before { content: "\f1b2"; }
.icon-skull:before { content: "\f1b3"; }
.icon-social-500px:before { content: "\f1b4"; }
.icon-social-adobe:before { content: "\f1b5"; }
.icon-social-amazon:before { content: "\f1b6"; }
.icon-social-android:before { content: "\f1b7"; }
.icon-social-apple:before { content: "\f1b8"; }
.icon-social-behance:before { content: "\f1b9"; }
.icon-social-bing:before { content: "\f1ba"; }
.icon-social-blogger:before { content: "\f1bb"; }
.icon-social-delicious:before { content: "\f1bc"; }
.icon-social-designer-news:before { content: "\f1bd"; }
.icon-social-deviant-art:before { content: "\f1be"; }
.icon-social-digg:before { content: "\f1bf"; }
.icon-social-dribbble:before { content: "\f1c0"; }
.icon-social-drive:before { content: "\f1c1"; }
.icon-social-dropbox:before { content: "\f1c2"; }
.icon-social-evernote:before { content: "\f1c3"; }
.icon-social-facebook:before { content: "\f1c4"; }
.icon-social-flickr:before { content: "\f1c5"; }
.icon-social-forrst:before { content: "\f1c6"; }
.icon-social-foursquare:before { content: "\f1c7"; }
.icon-social-game-center:before { content: "\f1c8"; }
.icon-social-github:before { content: "\f1c9"; }
.icon-social-google-plus:before { content: "\f1ca"; }
.icon-social-hacker-news:before { content: "\f1cb"; }
.icon-social-hi5:before { content: "\f1cc"; }
.icon-social-instagram:before { content: "\f1cd"; }
.icon-social-joomla:before { content: "\f1ce"; }
.icon-social-lastfm:before { content: "\f1cf"; }
.icon-social-linkedin:before { content: "\f1d0"; }
.icon-social-medium:before { content: "\f1d1"; }
.icon-social-myspace:before { content: "\f1d2"; }
.icon-social-orkut:before { content: "\f1d3"; }
.icon-social-path:before { content: "\f1d4"; }
.icon-social-picasa:before { content: "\f1d5"; }
.icon-social-pinterest:before { content: "\f1d6"; }
.icon-social-rdio:before { content: "\f1d7"; }
.icon-social-reddit:before { content: "\f1d8"; }
.icon-social-skillshare:before { content: "\f1d9"; }
.icon-social-skype:before { content: "\f1da"; }
.icon-social-smashing-mag:before { content: "\f1db"; }
.icon-social-snapchat:before { content: "\f1dc"; }
.icon-social-spotify:before { content: "\f1dd"; }
.icon-social-squidoo:before { content: "\f1de"; }
.icon-social-stack-overflow:before { content: "\f1df"; }
.icon-social-steam:before { content: "\f1e0"; }
.icon-social-stumbleupon:before { content: "\f1e1"; }
.icon-social-treehouse:before { content: "\f1e2"; }
.icon-social-tumblr:before { content: "\f1e3"; }
.icon-social-twitter:before { content: "\f1e4"; }
.icon-social-vimeo:before { content: "\f1e5"; }
.icon-social-windows:before { content: "\f1e6"; }
.icon-social-xbox:before { content: "\f1e7"; }
.icon-social-yahoo:before { content: "\f1e8"; }
.icon-social-yelp:before { content: "\f1e9"; }
.icon-social-youtube:before { content: "\f1ea"; }
.icon-social-zerply:before { content: "\f1eb"; }
.icon-social-zurb:before { content: "\f1ec"; }
.icon-sound:before { content: "\f1ed"; }
.icon-star:before { content: "\f1ee"; }
.icon-stop:before { content: "\f1ef"; }
.icon-strikethrough:before { content: "\f1f0"; }
.icon-subscript:before { content: "\f1f1"; }
.icon-superscript:before { content: "\f1f2"; }
.icon-tablet-landscape:before { content: "\f1f3"; }
.icon-tablet-portrait:before { content: "\f1f4"; }
.icon-target-two:before { content: "\f1f5"; }
.icon-target:before { content: "\f1f6"; }
.icon-telephone-accessible:before { content: "\f1f7"; }
.icon-telephone:before { content: "\f1f8"; }
.icon-text-color:before { content: "\f1f9"; }
.icon-thumbnails:before { content: "\f1fa"; }
.icon-ticket:before { content: "\f1fb"; }
.icon-torso-business:before { content: "\f1fc"; }
.icon-torso-female:before { content: "\f1fd"; }
.icon-torso:before { content: "\f1fe"; }
.icon-torsos-all-female:before { content: "\f1ff"; }
.icon-torsos-all:before { content: "\f200"; }
.icon-torsos-female-male:before { content: "\f201"; }
.icon-torsos-male-female:before { content: "\f202"; }
.icon-torsos:before { content: "\f203"; }
.icon-trash:before { content: "\f204"; }
.icon-trees:before { content: "\f205"; }
.icon-trophy:before { content: "\f206"; }
.icon-underline:before { content: "\f207"; }
.icon-universal-access:before { content: "\f208"; }
.icon-unlink:before { content: "\f209"; }
.icon-unlock:before { content: "\f20a"; }
.icon-upload-cloud:before { content: "\f20b"; }
.icon-upload:before { content: "\f20c"; }
.icon-usb:before { content: "\f20d"; }
.icon-video:before { content: "\f20e"; }
.icon-volume-none:before { content: "\f20f"; }
.icon-volume-strike:before { content: "\f210"; }
.icon-volume:before { content: "\f211"; }
.icon-web:before { content: "\f212"; }
.icon-wheelchair:before { content: "\f213"; }
.icon-widget:before { content: "\f214"; }
.icon-wrench:before { content: "\f215"; }
.icon-x-circle:before { content: "\f216"; }
.icon-x:before { content: "\f217"; }
.icon-yen:before { content: "\f218"; }
.icon-zoom-in:before { content: "\f219"; }
.icon-zoom-out:before { content: "\f21a"; }
