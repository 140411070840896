// base fonts
// ----------------------------------------
// ----------------------------------------
@font-face {font-family:"Typewriter";src:url(/lib/fonts/Typewriter.ttf)}
@font-face {font-family:"Typewriter-Light";src:url(/lib/fonts/Typewriter-Light.ttf)}
@font-face {font-family:"Display-Bold";src:url(/lib/fonts/SF-UI-Display-Bold.otf)}
@font-face {font-family:"Display-Medium";src:url(/lib/fonts/SF-UI-Display-Medium.otf)}
@font-face {font-family:"Display-Regular";src:url(/lib/fonts/SF-UI-Display-Regular.otf)}
@font-face {font-family:"Display-Light";src:url(/lib/fonts/SF-UI-Display-Light.otf)}
@font-face {font-family:"Display-Thin";src:url(/lib/fonts/SF-UI-Display-Thin.otf)}
@font-face {font-family:"Display-Ultralight";src:url(/lib/fonts/SF-UI-Display-Ultralight.otf)}


// top-level adjustments
// ----------------------------------------
// ----------------------------------------
.menu.vertical > li > a.title {
  outline: none;
}
a{
  transition: color .5s ease-in
}

a:hover{
  transition: color .5s ease-in
}

hr{
  border-bottom: 1px solid $dark-gray;
  // margin-top: 0;
}

label{
  color: $bright-white;
  font-family: $global-display-light;
  font-size: 1.25rem;
}


// GOSquared Chat icon
// ----------------------------------------
// ----------------------------------------
.gs04{
  bottom: 60px!important;
}


// branding
// ----------------------------------------
// ----------------------------------------
hr.brand{
  margin-bottom: .5rem;
  margin-top: 0;
  width: 60%;
}

// .brandLogo{
//   height:12rem;
//   margin:0 .5rem 0 0;
//   width:12rem
// }

.grid-x.brand{
  background: $matte-black;
  margin-bottom: 0;
  padding: 0 0 0;
}

h1.brand, h2.brand, h3.brand, .title-bar-left.brand{
  color: $bright-white;
  font-family: $global-brand;
  font-weight:normal!important;
  margin-bottom: .5rem;
  text-shadow: 0px 2px 5px rgba(100, 150, 255, .25);
  // transition: text-shadow 1s ease-in;
}

h4.brand{
  margin-bottom: 1.5rem;
}

h4.brand small{
  color: $medium-gray;
  margin-bottom: 1rem;
}

.title-bar-left.brand{
  font-family: $global-display-regular;
  font-size: 1.5rem;
  margin-right: 1.75rem;
  text-align: center;
}


// matrix block adjustments
// ----------------------------------------
// ----------------------------------------

// block quote
.block-quote p{
  color: $medium-gray;
  font-size: 1rem;
}
.block-quote{
  margin-bottom: 2rem;
}

// button block adjustments
.button.more{
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border: 1px solid rgba($matte-black, 0.25);
  box-shadow: none;
  background: $gold;
  font-family: $global-display-medium;
  font-size: 1rem;
  // font-weight: bold;
  color: $pure-black;
  margin-bottom: 3rem;
  padding: .5rem 1.25rem;
  // line-height: 1;
  border-radius: 6px;
  transition: color 1s ease-in, background 1.5s ease-out, border .25s ease-out, box-shadow 1.5s ease-out, -webkit-box-shadow 1.5s ease-out;
}

.button.more:hover{
  -webkit-box-shadow: 0px 0px 14px 0px rgba(255,255,255,.5);
  -moz-box-shadow: 0px 0px 14px 0px rgba(255,255,255,.5);
  box-shadow: 0px 0px 14px 0px rgba(255,255,255,.5);
  background: $matte-black;
  color: $bright-white!important;
  border: 1px solid rgba($silver, 0.5);
  transition: color 1s ease-in, background 1.5s ease-out, border .25s ease-out, box-shadow 1.5s ease-out, -webkit-box-shadow 1.5s ease-out;
}

.button.more:active{
  background: $white;
  color: $green;
  border: 1px solid $green;
  transition: color .25s ease-in, background .25s ease-out, border .25s ease-out, box-shadow .25s ease-out, -webkit-box-shadow .25s ease-out;
}

// client logo
.media-object{
  // background: $black;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
  @media screen and (min-width: 64em){
    .media-object{
      margin-bottom: 3rem;
    }
  }
.media-object-section img{
  margin-bottom: 0;
}
.thumbnail{
  border: 4px solid $dark-gray;
}
blockquote > em > p{
  color: $bright-white!important;
  // margin-bottom: 3rem;
}

// gallery adjustments
.owl-carousel{
  margin-bottom: 1rem;
}
  @media screen and (min-width: 64em){
    .owl-carousel{
      margin-bottom: 4rem;
    }
  }

.item img{
  margin-bottom: 0;
}

.owl-dots{
  margin-bottom: 3rem;
}

// heading block adjustments
h1:last-child, h2:last-child{
  color: $bright-white;
  // font-family: $global-display-regular;
  margin-bottom: 1rem;
}

  @media screen and (min-width: 64em){
    h1:last-child, h2:last-child{
      margin-bottom: 3rem;
    }
  }

// img block adjustments
img{
  margin-bottom: 1rem;
  margin-top: 0;
}
  @media screen and (min-width: 64em){
    img{
      margin-bottom: 3rem;
    }
  }

img:first-child{
margin-top: 0rem;
}

// img block captions
.pro-caption{
  position: relative;
}

.thumbnail img{
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    &:hover, &.active{
      -webkit-transform:scale(1.1);
      transform:scale(1.1);
      -webkit-transition: all 0.7s ease;
      transition: all 0.7s ease;
    }
}

.pro-caption > img{
  width: 100%;
}
.pro-caption > figcaption{
  bottom: 40%;
  left: 0;
  right: 0;
  margin-bottom: 1rem;
  padding: 0;
  position: absolute;
  width: 100%;
  text-align: center;
}

.pro-caption > figcaption h1, .pro-caption > figcaption h2, .pro-caption > figcaption h3, .pro-caption > figcaption h4, .pro-caption > figcaption h5, .pro-caption > figcaption h6, .pro-caption > figcaption p{
  color: $bright-white!important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
  font-family: $global-display-regular;
}

// pull quote block adjustments
.pull-quote{
  color: $gold;
  margin-bottom: 1rem;
}

  @media screen and (min-width: 64em){
    .pull-quote{
      margin-bottom: 3rem;
    }
  }

// testimonial block adjustments
blockquote.dark-quote{
  border-left: 1px solid $quote-line;
  color: $body-font-color;
  margin-bottom: 1rem;
}
  @media screen and (min-width: 64em){
    h1:last-child, h2:last-child{
      margin-bottom: 3rem;
    }
  }

img.testimonial{
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 10rem;
  margin-bottom: .5rem;
  width: 10rem;
}

// text block adjustments
.block-text > p{
  font-size: 1rem;
  line-height: 1.6;

}
  @media screen and (min-width: 64em){
      .block-text > p{
        font-size:1.125rem;
        line-height: 2;
      }
  }

.block-text > p > strong{
  color: $bright-white;
}
p:last-child{
  margin-bottom: 1rem;
}
  @media screen and (min-width: 64em){
    p:last-child{
      margin-bottom: 5rem;
    }
  }


// article feed adjustments
// ----------------------------------------
// ----------------------------------------
.feed img:first-child, .feed div.responsive-embed:first-child{
  margin-top: 1rem;
}

.feed img, .feed div.responsive-embed{
  margin-bottom: 1rem;
}

.feed p{
  color: $light-gray;
}

.feed p:last-child{
  margin-bottom: 3rem;
}

// top-bar menu adjustments
// ----------------------------------------
// ----------------------------------------
.top-bar, .top-bar ul{
  background: none;
}
.top-bar ul li:last-child{
  margin-right: 1.75rem;
}
.menu.vertical > li > a.title {
  background: $matte-black;
  color: $silver;
  font-size: 1rem;
  font-family: $global-display-light;
  transition: color 1.5s ease-out;
  &:hover, &.active{
    background: $silver;
    color: $black;
    transition: color 0.75s ease-out, background 0.75s ease-out;
  }
}

.dropdown.menu > li > a {
  // background: $bright-white;
  background: none;
}
.menu.vertical > li > a {
  // background: $bright-white;
  background: none;
  color: $black;
  font-size: 1rem;
  font-family: $global-display-regular;
  transition: color 1.5s ease-out;
  &:hover{
    background: $black;
    color: $primary-color;
    transition: color 0.75s ease-out, background 0.75s ease-out;
  }
  &.active{
    background: $silver;
    color: $black;
    transition: color 0.75s ease-out, background 0.75s ease-out;
  }
}

.menu.effects > li > a {
  color: $medium-gray;
  font-size: 1.25rem;
  font-family: $global-display-regular;
  transition: color 1.5s ease-out;
  &:hover, &.active{
    color: $bright-white;
    transition: color 0.5s ease-out;
  }
}

.menu.nested{
  margin-left: 0;
}

.top-bar.deflate{
  &.is-stuck {
  background: transparentize($matte-black, 0.25);
  border-bottom: 1px solid $dark-gray;
  }
}


// Latest articles section adjustments
// ----------------------------------------
// ----------------------------------------
.latest-articles img{
  margin-bottom: 1rem;
}

.card{
  background: none;
  border: none;
}

.card > h4 a{
  color: $silver;
  font-size: 1rem;
  font-family: $global-display-regular;
}
.card > p{
  color: $light-gray;
  font-size: .9rem;
  line-height: 2;
}

.card-section{
  padding: 0;
}

p.read-more{
  bottom: 0;
  font-size: .85rem;
  position: relative;;


}


// Footer adjustments
// ----------------------------------------
// ----------------------------------------
.cell.page-footer{
  background: $matte-black;
  border-top: 1px solid $dark-gray;
  padding: 1rem;
}
.page-footer a{
  color: $medium-gray;
}
.page-footer a:hover{
  color: $silver;
}

// color adjustments
// ----------------------------------------
// ----------------------------------------
.black{color:$black!important}
.blue{color:$blue!important}
.bright-white{color:$bright-white!important}
.dark-blue{color:$dark-blue!important}
.dark-gray{color:$dark-gray!important}
.dull-white{color:$dull-white!important}
.gold{color:$gold!important}
.green{color:$green!important}
.grey, .gray{color:$light-gray!important}
.light-grey{color:$light-gray!important}
.medium-gray{color:$medium-gray!important}
.orange{color:$orange!important}
.silver{color:$silver!important}
.purple{color:$purple!important}
.pink{color:$pink!important}
.primary-color{color:$primary-color!important}
.red{color:$red!important}
.silver{color:$silver!important}
.text-blue{color:$text-blue!important}
.white{color:$white!important}
.yellow{color:$yellow!important}


// font adjustments
// ----------------------------------------
// ----------------------------------------
p {font-size: 1rem}
label.small, .small-text, p.small, span.small li.small{font-size:.9125rem;line-height:1.6;margin:.25rem 0 .25rem}
label.smaller, .smaller-text, p.smaller, span.smaller, li.smaller{font-size:.8125rem;line-height:1.4;margin:0 0 .25rem}
h1 span.smaller, h2 span.smaller, h3 span.smaller, h4 span.smaller, h5 span.smaller, h6 span.smaller{font-size:.8125rem;line-height:1.35;margin:0 0 .25rem}
.bigger{font-size:1.25rem}
.bold{font-family:"Display-Bold"}
.light{font-family:"Display-Light"}
.italic{font-style:italic}
.lower{text-transform:lowercase}
.squash{line-height:1;margin:0 0 .125rem 0}


// spacing adjustments
// ----------------------------------------
// ----------------------------------------
.button{padding: 0.5rem .25rem}
.button-group.small .button{font-size:1rem;font-family: $global-display-bold}
.gap{margin-top:1rem}
.gap-bigger{margin-top:1.85rem}
.space{margin-top:.5rem}


// theme settings
// ----------------------------------------
// -----------------------------------------
.black-section{
    background: $pure-black;
}

.matte-section{
    background: $matte-black;
}

.inverted-section{
    background: $white;
    color: $matte-black;
}

.inverted-section h1, .inverted-section h2, .inverted-section a{
  color: $pure-black!important;
  // font-family: $global-display-bold;
}

.inverted-section h3, .inverted-section h4, .inverted-section h5{
  color: $medium-gray!important;
}

.inverted-section a.button, .inverted-section .button a{
  color: $pure-black!important;
}

.inverted-section label{
  color: $pure-black;
  font-family: $global-display-light;
  font-size: 1.25rem;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 2.5rem;
}
.inverted-section label:first-child{
  margin-top: 0;
}

.inverted-section .block-text > p > strong{
  color: $pure-black;
}

.contact-us.button{
  border-radius: 3px;
  padding: .75rem;
}

.inverted-section a.button.more{
  border: 2px solid rgba($silver, 1);
  background: $silver;
  color: $pure-black;
  transition:  border 1.5s ease-in, color 1s ease-in, background 1.5s ease-out, box-shadow 1.5s ease-out, -webkit-box-shadow 1.5s ease-out;
}
.inverted-section a.button.more:hover{
  border: 2px solid rgba($gold, .5);
  background: $matte-black;
  box-shadow: 0px 0px 14px 0px rgba(0,0,0,.5);
  color: $bright-white;
  transition:  border 1.5s ease-in, color 1s ease-in, background 1.5s ease-out, box-shadow 1.5s ease-out, -webkit-box-shadow 1.5s ease-out;
}
.inverted-section a.button.more:active{
  border: 1px solid rgba($matte-black, 0.25);
  background: $blue;
  color: $bright-white;
  transition:  border 1.5s ease-in, color 1s ease-in, background 1.5s ease-out, box-shadow 1.5s ease-out, -webkit-box-shadow 1.5s ease-out;
}

input#profotoTestCheck{
  display: none;
}


// contact form
// ---------------------------------------
// ---------------------------------------
h3.contact-form{
  color: $pure-black!important;
}

h4.contact-form{
  color: $dark-gray!important;
}

label.contact{
  font-family: $global-display-regular;
  font-size: 1rem;
  margin: 1rem 0 .25rem;
  margin-top: 1rem!important;
}

input.contact-form, textarea.contact-form{
  border-radius: 3;
  margin-bottom: .15rem;
  -moz-border-radius: 3;
  -webkit-border-radius: 3;
}

p.error{
  margin: 0 0 1rem 0;
}

button.contact-form{
  background: $gold!important;
  border-radius: 3;
  font-size: 1.5rem!important;
  padding: .75rem .25rem;
  margin-top: 1rem;
  -moz-border-radius: 3;
  -webkit-border-radius: 3;
}

button.contact-form:hover{
  background: $black!important;
  color: $white;
  transition:  background .5s ease-in;
}


// Reveal Fixes (adjusts modal Y placement)
// ---------------------------------------
// ---------------------------------------
.reveal{
  top: 200px!important;
}


// prevent fouc
// ---------------------------------------
// ---------------------------------------
.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
    .off-canvas {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
    .off-canvas {
      display: none;
    }
  }
}
