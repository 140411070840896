// Styleguide specific styles
// ---------------------------------------
// ---------------------------------------
.ss-section:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 2px dotted $silver;
  margin-bottom: 1rem;
}

.ss-code code {
  display: block;
  padding: 1rem;
  overflow-x: scroll;
  margin-bottom: 1.5rem;
}

.row.display {
  background: #eee;
  font-size: 11px;
  margin-bottom: 10px;
  line-height: 2rem;
  border: solid 1px #c6c6c6;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.row.display .columns:nth-child(2), .row.display .columns.small-centered, .row.display .columns.large-centered {
  background: #e1e1e1;
}
.row.display .columns.color-end {
  background: #d4d4d4;
}

.color-block {
  border-radius: 2px;
  display: block;
  padding: 8px 8px 6px;
  color: #333;
  text-transform: uppercase;
  border: 1px solid #ddd;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}
.color-block span {
  display: block;
  width: 100%;
  height: 100px;
  margin-bottom: 0.42857rem;
}
