// OWL CAROUSEL SETTINGS
// ----------------------

$color-base:			#884f4f !default;
$color-white:			#FFF !default;
$color-gray:			#D6D6D6 !default;

//nav

$nav-color: 			$color-white !default;
$nav-color-hover:		$color-white !default;
$nav-font-size: 		14px !default;
$nav-rounded: 			3px !default;
$nav-margin: 			5px !default;
$nav-padding: 			4px 7px !default;
$nav-background: 		$color-gray !default;
$nav-background-hover:	$color-base !default;
$nav-disabled-opacity: 	0.5 !default;

//dots

$dot-width:				10px !default;
$dot-height:			10px !default;
$dot-rounded:			30px !default;
$dot-margin: 			5px 7px !default;
$dot-background:		$color-gray !default;
$dot-background-active:	$color-base !default;

// OWL CAROUSEL DEFAULT STYLES
// ----------------------
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
      -ms-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }


// OWL Carousel theming
//  ---------------------------------

.owl-theme {
	// Styling Next and Prev buttons
	.owl-nav {
		margin-top: 10px;
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		[class*='owl-'] {
			color: $nav-color;
			font-size: $nav-font-size;
			margin: $nav-margin;
			padding: $nav-padding;
			background: $nav-background;
			display: inline-block;
			cursor: pointer;
			border-radius: 3px;

			&:hover {
				background: $nav-background-hover;
				color:$nav-color-hover;
				text-decoration: none;
			}
		}
		.disabled {
			opacity: $nav-disabled-opacity;
			cursor: default;
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		.owl-dot {
			display: inline-block;
			zoom: 1;
			// *display: inline;

			span {
				width: $dot-width;
				height: $dot-height;
				margin: $dot-margin;
				background: $dot-background;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: 30px;
			}

			&.active,
			&:hover {
				span {
					background: $dot-background-active;
				}
			}
		}
	}
}
